import Eonna1 from '../../../media/Eonna/L9/L9.webp'
import Eonna2 from '../../../media/Eonna/L9/L9 (2).webp'
import Eonna3 from '../../../media/Eonna/L9/L9 (3).webp'
import Eonna4 from '../../../media/Eonna/L9/L9 (4).webp'
import Eonna5 from '../../../media/Eonna/L9/L9 (5).webp'
import Eonna6 from '../../../media/Eonna/L9/L9 (6).webp'
import Eonna7 from '../../../media/Eonna/L9/L9 (7).webp'
import Eonna8 from '../../../media/Eonna/L9/L9 (8).webp'
import Eonna9 from '../../../media/Eonna/L9/L9 (9).webp'
import Eonna10 from '../../../media/Eonna/L9/L9 (10).webp'
import Eonna11 from '../../../media/Eonna/L9/L9 (11).webp'
import Eonna12 from '../../../media/Eonna/L9/L9 (12).webp'
import Eonna13 from '../../../media/Eonna/L9/L9 (13).webp'


const L9images =[
Eonna1,Eonna2,Eonna3,Eonna4,Eonna5,Eonna6,Eonna7,Eonna8,Eonna9,Eonna10,Eonna11,Eonna12,Eonna13
]

export default L9images;