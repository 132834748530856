import React from "react";
import "./otherbsd.scss";
import Produk1 from "./Produk1/produkbsd.jsx";

const rumah = () => {
  return (
    <div className="rumah">
      <div>
        <div className="Judul"></div>
        <div className="rumah-card">
          <Produk1 />
        </div>
      </div>
    </div>
  );
};

export default rumah;
