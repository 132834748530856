import React from "react";
import "./promo.scss";
import penawaran from "../../media/design hardsel 34 (15).webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

const Penawaran = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6287782434255&text=Halo%20Yerikho,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://eonna-bsdcity.com/%20(Promo)&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyload: true,
  };
  return (
    <div className="judulpromo">
      <div id="promo" className="judul">
        <h1>OUR PROMO</h1>
      </div>
      <div id="promo" className=" containerPenawaran">
        <div className="containercontent">
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free DP</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Subsidi Biaya KPR
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Diskon Ratusan Juta
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Biaya-Biaya</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Canopy</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free AC</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Free Smarthome System
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Wet Kitchen</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Sanitary by Kholer
              </span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Learn More
          </button>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={penawaran} />
        </div>
      </div>
    </div>
  );
};

export default Penawaran;
