import Namee1 from '../../../media/Eonna/Namee 8/Eonna (1).webp'
import Namee2 from '../../../media/Eonna/Namee 8/Eonna (2).webp'
import Namee3 from '../../../media/Eonna/Namee 8/Eonna (3).webp'
import Namee4 from '../../../media/Eonna/Namee 8/Eonna (4).webp'
import Namee5 from '../../../media/Eonna/Namee 8/Eonna (5).webp'
import Namee6 from '../../../media/Eonna/Namee 8/Eonna (6).webp'
import Namee7 from '../../../media/Eonna/Namee 8/Eonna (7).webp'
import Namee8 from '../../../media/Eonna/Namee 8/Eonna (8).webp'
import Namee9 from '../../../media/Eonna/Namee 8/Eonna (9).webp'
import Namee10 from '../../../media/Eonna/Namee 8/Eonna (10).webp'



const Namee8images =[
Namee1,Namee2,Namee3,Namee4,Namee5,Namee6,Namee7,Namee8,Namee9,Namee10
]

export default Namee8images;