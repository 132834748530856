import Enchante1 from '../../../media/Eonna/Others/Enchante/Enchante (1).webp'
import Enchante2 from '../../../media/Eonna/Others/Enchante/Enchante (2).webp'
import Enchante3 from '../../../media/Eonna/Others/Enchante/Enchante (3).webp'
import Enchante4 from '../../../media/Eonna/Others/Enchante/Enchante (4).webp'
import Enchante5 from '../../../media/Eonna/Others/Enchante/Enchante (5).webp'
import Enchante6 from '../../../media/Eonna/Others/Enchante/Enchante (6).webp'
import Enchante7 from '../../../media/Eonna/Others/Enchante/Enchante (7).webp'
import Enchante8 from '../../../media/Eonna/Others/Enchante/Enchante (8).webp'
import Enchante9 from '../../../media/Eonna/Others/Enchante/Enchante (9).webp'
import Enchante10 from '../../../media/Eonna/Others/Enchante/Enchante (10).webp'
import Enchante11 from '../../../media/Eonna/Others/Enchante/Enchante (11).webp'
import Enchante12 from '../../../media/Eonna/Others/Enchante/Enchante (12).webp'
import Enchante13 from '../../../media/Eonna/Others/Enchante/Enchante (13).webp'
import Enchante14 from '../../../media/Eonna/Others/Enchante/Enchante (14).webp'
import Enchante15 from '../../../media/Eonna/Others/Enchante/Enchante (15).webp'
import Enchante16 from '../../../media/Eonna/Others/Enchante/Enchante (16).webp'
import Enchante17 from '../../../media/Eonna/Others/Enchante/Enchante (17).webp'
import Enchante18 from '../../../media/Eonna/Others/Enchante/Enchante (18).webp'
import Enchante19 from '../../../media/Eonna/Others/Enchante/Enchante (19).webp'
import Enchante20 from '../../../media/Eonna/Others/Enchante/Enchante (20).webp'


const Enchanteimages =[
Enchante1,Enchante2,Enchante3,Enchante4,Enchante5,Enchante6,Enchante7,Enchante8,Enchante9,Enchante10,Enchante11,Enchante12,Enchante13,Enchante14,Enchante15,Enchante16,Enchante17,Enchante18,Enchante19,Enchante20
]

export default Enchanteimages;