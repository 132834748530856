import Hiera1 from '../../../media/Eonna/Others/Wynyard/Wynyard 9 (1).webp'
import Hiera2 from '../../../media/Eonna/Others/Wynyard/Wynyard 9 (2).webp'
import Hiera3 from '../../../media/Eonna/Others/Wynyard/Wynyard 9 (3).webp'
import Hiera4 from '../../../media/Eonna/Others/Wynyard/Wynyard 9 (4).webp'
import Hiera5 from '../../../media/Eonna/Others/Wynyard/Wynyard 9 (5).webp'
import Hiera6 from '../../../media/Eonna/Others/Wynyard/Wynyard 9 (6).webp'
import Hiera7 from '../../../media/Eonna/Others/Wynyard/Wynyard 9 (7).webp'
import Hiera8 from '../../../media/Eonna/Others/Wynyard/Wynyard 9 (8).webp'
import Hiera9 from '../../../media/Eonna/Others/Wynyard/Wynyard 9 (9).webp'
import Hiera10 from '../../../media/Eonna/Others/Wynyard/Wynyard 9 (10).webp'




const Hieraimages =[
Hiera1,Hiera2,Hiera3,Hiera4,Hiera5,Hiera6,Hiera7,Hiera8,Hiera9,Hiera10
]

export default Hieraimages;