import Eonna1 from '../../../media/Eonna/L10/L10.webp'
import Eonna2 from '../../../media/Eonna/L10/L10 (2).webp'
import Eonna3 from '../../../media/Eonna/L10/L10 (3).webp'
import Eonna4 from '../../../media/Eonna/L10/L10 (4).webp'
import Eonna5 from '../../../media/Eonna/L10/L10 (5).webp'
import Eonna6 from '../../../media/Eonna/L10/L10 (6).webp'
import Eonna7 from '../../../media/Eonna/L10/L10 (7).webp'
import Eonna8 from '../../../media/Eonna/L10/L10 (8).webp'
import Eonna9 from '../../../media/Eonna/L10/L10 (9).webp'
import Eonna10 from '../../../media/Eonna/L10/L10 (10).webp'
import Eonna11 from '../../../media/Eonna/L10/L10 (11).webp'
import Eonna12 from '../../../media/Eonna/L10/L10 (12).webp'
import Eonna13 from '../../../media/Eonna/L10/L10 (13).webp'
import Eonna14 from '../../../media/Eonna/L10/L10 (14).webp'
import Eonna15 from '../../../media/Eonna/L10/L10 (15).webp'
import Eonna16 from '../../../media/Eonna/L10/L10 (16).webp'


const L10images =[
Eonna1,Eonna2,Eonna3,Eonna4,Eonna5,Eonna6,Eonna7,Eonna8,Eonna9,Eonna10,Eonna11,Eonna12,Eonna13,Eonna14,Eonna15,Eonna16
]

export default L10images;