import Adora1 from '../../../media/Eonna/Others/Adora Primes/1.webp'
import Adora2 from '../../../media/Eonna/Others/Adora Primes/2.webp'
import Adora3 from '../../../media/Eonna/Others/Adora Primes/3.webp'
import Adora4 from '../../../media/Eonna/Others/Adora Primes/4.webp'
import Adora5 from '../../../media/Eonna/Others/Adora Primes/5.webp'
import Adora6 from '../../../media/Eonna/Others/Adora Primes/6.webp'
import Adora7 from '../../../media/Eonna/Others/Adora Primes/7.webp'
import Adora8 from '../../../media/Eonna/Others/Adora Primes/8.webp'
import Adora9 from '../../../media/Eonna/Others/Adora Primes/9.webp'
import Adora10 from '../../../media/Eonna/Others/Adora Primes/10.webp'
import Adora11 from '../../../media/Eonna/Others/Adora Primes/11.webp'
import Adora12 from '../../../media/Eonna/Others/Adora Primes/12.webp'
import Adora13 from '../../../media/Eonna/Others/Adora Primes/13.webp'
import Adora14 from '../../../media/Eonna/Others/Adora Primes/14.webp'
import Adora15 from '../../../media/Eonna/Others/Adora Primes/15.webp'
import Adora16 from '../../../media/Eonna/Others/Adora Primes/16.webp'


const Adoraimages =[
Adora1,Adora2,Adora3,Adora4,Adora5,Adora6,Adora7,Adora8,Adora9,Adora10,Adora11,Adora12,Adora13,Adora14,Adora15,Adora16
]

export default Adoraimages;