import React from 'react'
import "./rumah.scss"
import Produk1 from "./Produk1/Produk1.jsx"
import Produk2 from './Produk2/Produk2.jsx'



const rumah = () => {
  return (
    <div className='rumah'>
        <div id='rumah' className='container-header1'> Aerra
        </div>
        <div>
            <div className='Judul'></div> 
            <div className='rumah-card'>
            <Produk1/>
            <Produk2/>
            </div>
        </div>
    </div>
  )
}

export default rumah;