import Fasilitas1 from '../../media/fasilitas eonna/Fasilitas(1).webp'
import Fasilitas2 from '../../media/fasilitas eonna/Fasilitas(2).webp'
import Fasilitas3 from '../../media/fasilitas eonna/Fasilitas(3).webp'
import Fasilitas4 from '../../media/fasilitas eonna/Fasilitas(4).webp'
import Fasilitas5 from '../../media/fasilitas eonna/Fasilitas(5).webp'
import Fasilitas6 from '../../media/fasilitas eonna/Fasilitas(6).webp'
import Fasilitas7 from '../../media/fasilitas eonna/Fasilitas(7).webp'





const fasilitasimages =[
    Fasilitas1,Fasilitas2,Fasilitas3,Fasilitas4,Fasilitas5,Fasilitas6,Fasilitas7
]

export default fasilitasimages;