import Eonna1 from '../../../media/Eonna/L8/L8.webp'
import Eonna2 from '../../../media/Eonna/L8/L8 (2).webp'
import Eonna3 from '../../../media/Eonna/L8/L8 (3).webp'
import Eonna4 from '../../../media/Eonna/L8/L8 (4).webp'
import Eonna5 from '../../../media/Eonna/L8/L8 (5).webp'
import Eonna6 from '../../../media/Eonna/L8/L8 (6).webp'
import Eonna7 from '../../../media/Eonna/L8/L8 (7).webp'
import Eonna8 from '../../../media/Eonna/L8/L8 (8).webp'
import Eonna9 from '../../../media/Eonna/L8/L8 (9).webp'
import Eonna10 from '../../../media/Eonna/L8/L8 (10).webp'


const L8images =[
Eonna1,Eonna2,Eonna3,Eonna4,Eonna5,Eonna6,Eonna7,Eonna8,Eonna9,Eonna10
]

export default L8images;