import Tanakayu1 from '../../../media/Eonna/Others/Vasya Micro/Vasya (1).webp'
import Tanakayu2 from '../../../media/Eonna/Others/Vasya Micro/Vasya (2).webp'
import Tanakayu3 from '../../../media/Eonna/Others/Vasya Micro/Vasya (3).webp'
import Tanakayu4 from '../../../media/Eonna/Others/Vasya Micro/Vasya (4).webp'
import Tanakayu5 from '../../../media/Eonna/Others/Vasya Micro/Vasya (5).webp'
import Tanakayu6 from '../../../media/Eonna/Others/Vasya Micro/Vasya (6).webp'
import Tanakayu7 from '../../../media/Eonna/Others/Vasya Micro/Vasya (7).webp'
import Tanakayu8 from '../../../media/Eonna/Others/Vasya Micro/Vasya (8).webp'



const Tanakayuimages =[
Tanakayu1,Tanakayu2,Tanakayu3,Tanakayu4,Tanakayu5,Tanakayu6,Tanakayu7,Tanakayu8
]

export default Tanakayuimages;