import NameeL91 from '../../../media/Eonna/Namee 9/Namee9 1 (1).webp'
import NameeL92 from '../../../media/Eonna/Namee 9/Namee9 1 (2).webp'
import NameeL93 from '../../../media/Eonna/Namee 9/Namee9 1 (3).webp'
import NameeL94 from '../../../media/Eonna/Namee 9/Namee9 1 (4).webp'
import NameeL95 from '../../../media/Eonna/Namee 9/Namee9 1 (5).webp'
import NameeL96 from '../../../media/Eonna/Namee 9/Namee9 1 (6).webp'
import NameeL97 from '../../../media/Eonna/Namee 9/Namee9 1 (7).webp'
import NameeL98 from '../../../media/Eonna/Namee 9/Namee9 1 (8).webp'
import NameeL99 from '../../../media/Eonna/Namee 9/Namee9 1 (9).webp'
import NameeL910 from '../../../media/Eonna/Namee 9/Namee9 1 (10).webp'
import NameeL911 from '../../../media/Eonna/Namee 9/Namee9 1 (11).webp'
import NameeL912 from '../../../media/Eonna/Namee 9/Namee9 1 (12).webp'
import NameeL913 from '../../../media/Eonna/Namee 9/Namee9 1 (13).webp'
import NameeL914 from '../../../media/Eonna/Namee 9/Namee9 1 (14).webp'

const Namee9images =[
NameeL91,NameeL92,NameeL93,NameeL94,NameeL95,NameeL96,NameeL97,NameeL98,NameeL99,NameeL910,NameeL911,NameeL912,NameeL913,NameeL914
]

export default Namee9images;